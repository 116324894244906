import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { baseURL, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const credentials = {
      username,
      password
    };

    return this.http.post(`${baseURL}login`, credentials, {
      headers,
      responseType: 'text' // Add this line
    })
     .pipe(
        map((response: string) => {
          if (response) {
            return { authToken: response, roleId: 'ADMIN' }; // Add static roleId for now
          } else {
            console.log('Token not found in response body');
            return {}; // Add this line
          }
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status !== 200) {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      return throwError('Something bad happened; please try again later.');
    } else {
      return throwError(error.error);
    }
  }

  getUserDataByUserName(userName:any):Observable<any>{
    return this.http.get(`${baseURL}users/${VERSION}/name?userName=${userName}`)
  }

  changePassword(userPasswordData:any):Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${baseURL}users/${VERSION}/changePassword`,userPasswordData,{headers:headers})
  }
}