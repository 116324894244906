import { HttpHeaders } from "@angular/common/http";

// export const baseURL = "http://localhost:8090/";
export const baseURL = "http://13.201.173.142:8090/";
// export const baseURL = "http://13.201.126.149/";
export const VERSION = "v1";
export function getHeaders(): { headers: HttpHeaders } {
    const authToken = localStorage.getItem('authToken');
    let headers = new HttpHeaders();
    if (authToken) {
        headers = headers.set('Authorization', `Bearer ${authToken}`);
    }
    headers = headers.set('Content-Type', 'application/json'); 
    return { headers };
}